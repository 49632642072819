import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

export default class DeleteOrganizationUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    this.setState({ loading: true });

    return rs.request({ op: 'deleteOrganizationUser', userId: this.props.user.id })
      .then(() => {
        this.setState({ loading: false });
        toast.success('User was deleted successfully!')
        this.toggle();
        rs.performStateOperation('getOrganizationUsers');
      })
      .catch(error => {
        this.setState({ loading: false })
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <React.Fragment>
      <button type="button" className="btn btn-danger btn-sm" onClick={() => this.toggle()}
        title="Delete User" >
        <i className="fa fa-trash"></i>
      </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size='md'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Delete User
            </ModalHeader>
            <ModalBody>
              <p>
                Do you really want to delete the user <strong>{this.props.user.email}</strong>?
              </p>
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-danger mr-2'
              >{this.state.loading ? 'Deleting...' : 'Delete'}</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
