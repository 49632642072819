import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import NumericInput from 'react-numeric-input';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EditableOnOff = ({ value, ...props }) => {
  return (
    <Toggle
      on={<span>ON</span>}
      off={<span>OFF</span>}
      size="sm"
      offstyle="danger"
      handleClassName="bootstrap-on-off-handle"
      active={value}
      {...props}
    />
  );
};

export default class EditTrack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      track: this.props.track
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTrackModeChange = this.handleTrackModeChange.bind(this);
    this.handleTrackModeToggleChange = this.handleTrackModeToggleChange.bind(this);
    this.handleNumericChange = this.handleNumericChange.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      loading: false,
      track: this.props.track
    });
  }

  handleTrackModeToggleChange(name) {
    this.setState({
      track: {
        ...this.state.track,
        mode: {
          ...this.state.track.mode,
          [name]: !this.state.track.mode[name]
        }
      }
    });
  }

  handleTrackModeChange(event) {
    this.setState({
      track: {
        ...this.state.track,
        mode: {
          ...this.state.track.mode,
          [event.target.name]: event.target.value
        }
      }
    });
  }

  handleNumericChange(value, _displayValue, target) {
    this.setState({
      track: {
        ...this.state.track,
        [target.name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const number = this.props.number;
    const currentTrack = this.props.track;
    const editedTrack = this.state.track;

    let patchEmpty = true;
    const patch = {};

    if(currentTrack.mode.upOpMode !== editedTrack.mode.upOpMode) {
      patchEmpty = false;
      patch.mode = {
        ...patch.mode,
        upOpMode: editedTrack.mode.upOpMode
      };
    }
    if(currentTrack.mode.upCycling !== editedTrack.mode.upCycling) {
      patchEmpty = false;
      patch.mode = {
        ...patch.mode,
        upCycling: editedTrack.mode.upCycling
      };
    }
    if(currentTrack.mode.downOpMode !== editedTrack.mode.downOpMode) {
      patchEmpty = false;
      patch.mode = {
        ...patch.mode,
        downOpMode: editedTrack.mode.downOpMode
      };
    }
    if(currentTrack.mode.downCycling !== editedTrack.mode.downCycling) {
      patchEmpty = false;
      patch.mode = {
        ...patch.mode,
        downCycling: editedTrack.mode.downCycling
      };
    }

    if(currentTrack.upTriggerCount !== editedTrack.upTriggerCount) {
      patchEmpty = false;
      patch.upTriggerCount = editedTrack.upTriggerCount;
    }
    if(currentTrack.upPumpTime !== editedTrack.upPumpTime) {
      patchEmpty = false;
      patch.upPumpTime = editedTrack.upPumpTime;
    }
    if(currentTrack.downTriggerCount !== editedTrack.downTriggerCount) {
      patchEmpty = false;
      patch.downTriggerCount = editedTrack.downTriggerCount;
    }
    if(currentTrack.downPumpTime !== editedTrack.downPumpTime) {
      patchEmpty = false;
      patch.downPumpTime = editedTrack.downPumpTime;
    }

    if (patchEmpty === true) {
      return this.toggle();
    }

    this.setState({ loading: true });

    this.props.updateDeviceState([{
      [`track_${number}`]: patch
    }])
      .then(() => {
        this.toggle();
      })
      .catch(() => {
        this.setState({ loadgin: false });
      });
  }

  render() {
    const track = this.state.track;

    return (
      <React.Fragment>
        <button type="button" className="btn btn-primary btn-sm track-edit-btn" onClick={() => this.toggle()}>
          <i className="fa fa-edit"></i>
        </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size="md">
          <form onSubmit={this.handleSubmit} className="edit-track-form">
            <ModalHeader toggle={() => this.toggle()}>
              Edit Track State
            </ModalHeader>
            <ModalBody>
              <div className="alert alert-info">
                Your update can take few seconds to be processed.
              </div>
              {
                this.state.loading ?
                (
                  <div className="text-center">
                    <i className="fa fa-spinner fa-spin fa-lg"></i>
                  </div>
                ) :
                (
                  <React.Fragment>
                    <div className="form-group">
                      <label>Up Op Mode</label>
                      <select value={track.mode.upOpMode} name="upOpMode" className="form-control" onChange={this.handleTrackModeChange}>
                        <option value="accumulating">accumulating</option>
                        <option value="resetting">resetting</option>
                        <option value="off">off</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Down Op Mode</label>
                      <select value={track.mode.downOpMode} name="downOpMode" className="form-control" onChange={this.handleTrackModeChange}>
                        <option value="accumulating">accumulating</option>
                        <option value="resetting">resetting</option>
                        <option value="off">off</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Up Cycling:</label>
                      <EditableOnOff value={track.mode.upCycling} onClick={() => this.handleTrackModeToggleChange('upCycling')} />
                    </div>
                    <div className="form-group">
                      <label>Down Cycling:</label>
                      <EditableOnOff value={track.mode.downCycling} onClick={() => this.handleTrackModeToggleChange('downCycling')} />
                    </div>
                    <div className="form-group">
                      <label>Up Trigger</label>
                      <NumericInput className="form-control" name="upTriggerCount" value={track.upTriggerCount} onChange={this.handleNumericChange} />
                      <small className='form-text text-muted'>Lubrication trigger axle count in up direction</small>
                    </div>
                    <div className="form-group">
                      <label>Down Trigger</label>
                      <NumericInput className="form-control" name="downTriggerCount" value={track.downTriggerCount} onChange={this.handleNumericChange} />
                      <small className='form-text text-muted'>Lubrication trigger axle count in down direction</small>
                    </div>
                    <div className="form-group">
                      <label>Up Pump Time</label>
                      <NumericInput className="form-control" name="upPumpTime" value={track.upPumpTime} onChange={this.handleNumericChange} />
                      <small className='form-text text-muted'>Pump timeout duration in up direction</small>
                    </div>
                    <div className="form-group">
                      <label>Down Pump Time</label>
                      <NumericInput className="form-control" name="downPumpTime" value={track.downPumpTime} onChange={this.handleNumericChange} />
                      <small className='form-text text-muted'>Pump timeout duration in down direction</small>
                    </div>
                  </React.Fragment>
                )
              }
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-primary">Save</button>{' '}
              <button type="button" className="btn btn-secondary" onClick={() => this.toggle()}>Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
