import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import './index.scss';
import logo from '../assets/logo.png';


export default class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: null });
    this.setState({ loading: true });

    const { email, password } = this.state;

    axios
      .post('/api/login', { email, password })
      .then(({ data }) => {
        localStorage.setItem('auth_token', data.token);
        location.reload();
      })
      .catch((error) => {
        this.setState({ error: error.response.data, loading: false });
      });
  }

  render() {
    return (
      <div className='d-flex flex-column justify-content-center w-100 h-100 mx-auto my-auto login'>
        <form onSubmit={this.handleSubmit} className='bg-light mx-auto w-100 px-5 pt-5 rounded'>
          <div className='form-group text-center'>
            <img className='logo' src={logo} />
          </div>
          {
            this.state.error &&
            <div className='form-group'>
              <div className='alert alert-warning'>
                {this.state.error.message}
              </div>
            </div>
          }

          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='text' name='email' id='email' value={this.state.email}
              onChange={this.handleChange} autoFocus autoComplete='email'
              className='form-control' />
          </div>

          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              type='password' name='password' id='password'
              value={this.state.password} onChange={this.handleChange}
              autoComplete='current-password' className='form-control' />
          </div>
          <button type='submit' className='btn btn-lg btn-primary btn-block mb-4'>
            {this.state.loading ? <span className='fas fa-spinner fa-spin' /> : 'Login'}
          </button>
          <div className='text-center text-muted pb-2'>
            <small>&copy; 2018 JSG Industrial System Pty Ltd</small>
          </div>
        </form>
      </div>
    );
  }
}
