import './index.scss';
import React from 'react';

import NavProfile from './NavProfile';
import NavActions from './NavActions';

import StateHome from '../RemoteService/StateHome';

import logo from '../assets/logo.png';


class Layout extends React.Component {

  goToHome() {
    const {rs} = this.props;
    rs.transitionToState(new StateHome(rs));
  }

  render () {
    const {state, rs} = this.props;
    const me = rs.getMe();

    return (
      <div className='layout'>
        <nav className='navbar navbar-light bg-blue'>
          <a onClick={this.goToHome.bind(this)} href='#'>
            <img className='navbar-logo' src={logo} />
          </a>
          <div className='nav-actions'>
            <NavActions rs={rs} />
            <NavProfile rs={rs} />
          </div>
        </nav>
        { this.props.children }
      </div>
    );
  }
}

export default {
  Layout
};
