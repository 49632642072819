import State from './State';
import { toast } from 'react-toastify';

export default class StateUnassignedDevices extends State {
  constructor(rs) {
    super(rs);
    this.unassignedDevices = [];
    this.organizations = []
  }
  serialize() {
    return {
      name: 'STATE_UNASSIGNED_DEVICES',
      unassignedDevices: this.unassignedDevices,
      organizations: this.organizations
    };
  }
  onMount() {
    return Promise.all([
      this.getUnassingedDevices(),
      this.getOrganizations()
    ]);
  }
  getUnassingedDevices() {
    this.unassignedDevices = [];
    return this.rs.request({ op: 'getUnassingedDevices' })
      .then(({ devices }) => {
        this.unassignedDevices = devices;
        this.rs.emitState();
      })
      .catch(e => {
        alert('failed to get unassigned devices');
        console.error(e);
      });
  }

  getOrganizations() {
    this.organizations = [];
    this.rs.request({ op: 'getOrganizations' })
      .then(({ organizations }) => {
        this.organizations = organizations;
        this.rs.emitState();
      })
      .catch(e => {
        alert('failed to get organizations');
        console.error(e);
      });
  }

  assignDeviceToOrganization(deviceId, organizationId) {
    return this.rs.request({ op: 'assignDeviceToOrganization', deviceId, organizationId })
      .then(() => {
        return this.rs.request({op: 'getDeviceList'})
      })
      .then(({devices}) => {
        this.rs.setDevices(devices);
        return this.getUnassingedDevices()
      })
      .then(() => {
        toast.success('Device was assigned to organization successfully!')
      })
      .catch(e => {
        alert('failed to assign to organizations');
        console.error(e);
      });
  }
}
