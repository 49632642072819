import State from './State';
//import StateHome from './StateHome';
//import StateLogin from './StateLogin';


export default class StateLogin extends State {
  constructor(rs) {
    super(rs);
  }
  serialize() {
    return {
      name: 'STATE_LOGIN'
    };
  }
}
