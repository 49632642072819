import React from 'react';

export default ({userEmail, userMobile, userRole, canAddSuperAdmin, handleChange}) => {
  return (
    <React.Fragment>
      <div className='form-group'>
        <label forhtml='user-email'>Email</label>
        <input
          type='email'
          className='form-control'
          id='user-email'
          name='userEmail'
          value={userEmail}
          onChange={handleChange}
          required
          placeholder='email@address.com'
          autoComplete='email' />
      </div>
      <div className='form-group'>
        <label forhtml='user-email'>Mobile</label>
        <input
          type='text'
          className='form-control'
          id='user-mobile'
          name='userMobile'
          value={userMobile}
          pattern='^04[0-9]{8}$'
          placeholder='0412345678'
          onChange={handleChange}
          autoComplete='mobile' />
      </div>
      <div className='form-group'>
        <label forhtml='user-role'>Role</label>
        <select
          className='form-control'
          id='user-role'
          name='userRole'
          value={userRole}
          onChange={handleChange}
          required
          autoComplete='role'
        >
          <option value='' />
          <option value='ADMIN'>ADMIN</option>
          <option value='GUEST'>GUEST</option>
          {canAddSuperAdmin === true && (
            <option value='SUPER_ADMIN'>SUPER ADMIN</option>
          )}
          <option value='TECHNICIAN'>TECHNICIAN</option>
        </select>
      </div>
    </React.Fragment>
  );
}
