import React from 'react';

import './index.scss';
import StyleGuide from '../StyleGuide';
import AddOrganizationForm from './AddOrganizationForm';
import EditOrganizationForm from './EditOrganizationForm';
import AssignedDevices from './AssignedDevices';

export default class Organizations extends React.Component {
  takesOrganizationAdminIdentity(organizationId) {
    const {rs} = this.props;
    rs.performStateOperation('takesOrganizationAdminIdentity', organizationId);
  }

  render() {
    const { rs, state } = this.props;

    const organizations = state.organizations.map(organization => {
      return (
        <tr key={organization.id}>
          <th scope="row">{organization.id}</th>
          <td>{organization.name}</td>
          <td>
            <div className="btn-group" role="group">
              <EditOrganizationForm organization={organization} rs={rs}/>
              <AssignedDevices organizationId={organization.id} rs={rs}/>
              <button type="button" className="btn btn-info btn-sm"
                onClick={() => this.takesOrganizationAdminIdentity(organization.id)} title={'Takes org\'s admin identity'}>
                <i className="fa fa-user"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <StyleGuide.Layout rs={rs} state={state}>
        <div className="organizations-page container">
          <div className="row">
            <div className="col-12">
              <AddOrganizationForm rs={rs}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {organizations}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </StyleGuide.Layout>
    );
  }
}
