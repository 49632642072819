import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

export default class AssignedDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      devices: [],
      loading: false
    };

    this.toggle = this.toggle.bind(this);
    this.removeDeviceFromOrganization = this.removeDeviceFromOrganization.bind(this);
  }

  open() {
    const {rs, organizationId} = this.props;

    this.setState({
      modal: true,
      loading: true
    });

    rs.request({ op: 'getAssignedDevices', organizationId: organizationId })
      .then(({ devices }) => {
        this.setState({
          devices,
          loading: false
        })
      })
      .catch(e => {
        this.close();
        alert('failed to get unassigned devices');
        console.error(e);
      });
  }

  close() {
    this.setState({
      modal: false,
      devices: [],
      loading: false
    });
  }

  toggle() {
    this.state.modal ? this.close() : this.open();
  }

  removeDeviceFromOrganization(deviceId) {
    const {rs, organizationId} = this.props;

    this.setState({
      loading: true
    });

    rs.request({ op: 'removeDeviceFromOrganization', organizationId: organizationId, deviceId: deviceId })
      .then(() => {
        this.setState({
          devices: this.state.devices.filter(device => device.deviceId !== deviceId),
          loading: false
        });
        toast.success('Device was removed from organization successfully!');
      })
      .catch(e => {
        this.close();
        alert('failed to get unassigned devices');
        console.error(e);
      });
  }

  devicesOrEmptyMessage() {
    const devices = this.state.devices.map(device => {
      return (
        <div href="#" className="list-group-item" key={device.deviceId}>
          {device.name}

          <button
            className="btn btn-danger btn-sm float-right"
            onClick={() => this.removeDeviceFromOrganization(device.deviceId)}>
            <i className="fa fa-unlink"></i>
          </button>
        </div>
      );
    });

    const emptyMessage = (
      <div href="#" className="list-group-item">
        No devices available
      </div>
    )

    return devices.length ? devices : emptyMessage;
  }

  loaderOrDevices() {
    const loader = (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin fa-lg"></i>
      </div>
    );

    const devices = (
      <div className="list-group" role="tablist">
        {this.devicesOrEmptyMessage()}
      </div>
    );

    return this.state.loading ? loader : devices;
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-success btn-sm" onClick={() => this.toggle()}>
          <i className="fa fa-tablet"></i>
        </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size="md">
          <ModalHeader toggle={() => this.toggle()}>
            Devices
          </ModalHeader>
          <ModalBody>
            {this.loaderOrDevices()}
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-secondary" onClick={() => this.toggle()}>Cancel</button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
