import Promise from 'bluebird';


export default class State {
  constructor(rs) {
    this.rs = rs;
  }
  onMount() {
    return Promise.resolve();
  }
  onUnmount() {
    return Promise.resolve();
  }
  serialize() {
    return {
      name: null
    }
  }
  onMsg(msg) {
  }
}

