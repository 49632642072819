import {Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import moment from 'moment';
import React from 'react';
import classnames from 'classnames';

import './index.scss';


export default class CalendarControl extends React.Component {
  constructor(props) {
    super(props);
    const {date} = this.props;
    const today = moment().hour(0).minute(0).second(0).millisecond(0);
    const d = (date !== null ? moment(date) : moment()).hour(0).minute(0).second(0).millisecond(0);
    this.state = {
      open: false,
      today,
      d,
      title: d.format('MMM YYYY'),
      selectedDate: d,
      weeks: this.refreshWeeks(today, d, d)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date === this.props.date) return;
    console.log('componentDidUpdate', this.props.date);
    const {date} = this.props;
    const today = moment().hour(0).minute(0).second(0).millisecond(0);
    const d = (date !== null ? moment(date) : moment()).hour(0).minute(0).second(0).millisecond(0);
    this.setState({
      ...this.state,
      today,
      d,
      title: d.format('MMM YYYY'),
      selectedDate: d,
      weeks: this.refreshWeeks(today, d, d)
    });
  }

  refreshWeeks(today, selected, d) {
    const weeks = [];
    const i = moment(d).date(1);
    while (i.month() === d.month()) {
      const w = i.week();
      if (!weeks[w]) {
        weeks[w] = new Array(7).fill(null);
      }
      weeks[w][i.day()] = {
        today: today.valueOf() === i.valueOf(),
        selected: selected.valueOf() === i.valueOf(),
        date: i.date(),
        d: i.format('YYYY-MM-DD')
      };
      i.add(1, 'day');
    }
    return weeks.filter(w => w);
  }

  onToggle() {
    const {open, selectedDate} = this.state;
    if (open === true) {
      const today = moment().hour(0).minute(0).second(0).millisecond(0);
      this.setState({
        open: false,
        today,
        d: selectedDate,
        title: selectedDate.format('MMM YYYY'),
        selectedDate,
        weeks: this.refreshWeeks(today, selectedDate, selectedDate)
      });
    } else {
      this.setState({
        ...this.state,
        open: true
      });
    }
  }

  onChangeMonth(a, e) {
    e.preventDefault();
    const today = moment().hour(0).minute(0).second(0).millisecond(0);
    const d = moment(this.state.d).add(a, 'month');
    this.setState({
      ...this.state,
      d,
      title: d.format('MMM YYYY'),
      weeks: this.refreshWeeks(today, this.state.selectedDate, d)
    });
  }

  onSelectDate(d, e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      open: false
    });
    this.props.onSelect(d);
  }

  renderDay(d, i) {
    if (d === null) {
      return (
        <div className='calendar-cell p-2 text-center' key={i} />
      );
    }
    return (
      <div
        className={classnames(
          'calendar-cell p-2 text-center rounded',
          {'bg-primary': d.selected === true}
        )}
        key={i}
      >
        <a
          href='#'
          className={classnames({
            'text-dark': d.selected !== true,
            'text-light': d.selected === true,
            'font-weight-bold': d.today === true
          })}
          onClick={this.onSelectDate.bind(this, d.d)}
        >{d.date}</a>
      </div>
    );
  }

  renderWeek(w, i) {
    return (
      <div className='d-flex flex-nowrap' key={i}>
        {
          w.map(this.renderDay.bind(this))
        }
      </div>
    );
  }

  render() {
    return (
      <Dropdown isOpen={this.state.open} toggle={this.onToggle.bind(this)} className='calendar-control'>
        <DropdownToggle
          tag='span'
          className='form-control alert-dark calendar-input'
          onClick={this.onToggle.bind(this)}
        >{this.state.selectedDate.format('YYYY-MM-DD')}</DropdownToggle>
        <DropdownMenu className='calendar-table p-2'>
          <div className='d-flex justify-content-between'>
            <a href='#' onClick={this.onChangeMonth.bind(this, -1)}>
              <i className='fas fa-chevron-left' />
            </a>
            {this.state.title}
            <a href='#' onClick={this.onChangeMonth.bind(this, 1)}>
              <i className='fas fa-chevron-right' />
            </a>
          </div>
          <div className='d-flex flex-nowrap'>
            <div className='calendar-cell p-2 text-center font-weight-bold'>S</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>M</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>T</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>W</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>T</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>F</div>
            <div className='calendar-cell p-2 text-center font-weight-bold'>S</div>
          </div>
          {
            this.state.weeks.map(this.renderWeek.bind(this))
          }
        </DropdownMenu>
      </Dropdown>
    );
  }
}
