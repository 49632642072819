import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import RoleRequired from './RoleRequired';
import StateReports from '../RemoteService/StateReports';
import StateOrganizations from '../RemoteService/StateOrganizations';
import StateUnassignedDevices from '../RemoteService/StateUnassignedDevices';
import StateOrganizationUsers from '../RemoteService/StateOrganizationUsers';

export default class NavActions extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  goToReports() {
    const {rs} = this.props;
    rs.transitionToState(new StateReports(rs));
  }

  goToOrganizations() {
    const {rs} = this.props;
    rs.transitionToState(new StateOrganizations(rs));
  }

  goToUnassignedDevices() {
    const {rs} = this.props;
    rs.transitionToState(new StateUnassignedDevices(rs));
  }

  goToOrganizationUsers() {
    const {rs} = this.props;
    rs.transitionToState(new StateOrganizationUsers(rs));
  }

  render() {
    const { rs, className } = this.props;
    const me = rs.getMe();

    return (
      <RoleRequired user={me} role='ADMIN'>
        <button
          className='nav-action d-none d-lg-block btn btn-primary btn-sm'
          onClick={this.goToReports.bind(this)}
        >Reports</button>
        <button
          className='nav-action d-none d-lg-block btn btn-primary btn-sm'
          onClick={this.goToOrganizationUsers.bind(this)}
        >Users</button>
        <RoleRequired user={me} role='SUPER_ADMIN'>
          <button
            className='nav-action d-none d-lg-block btn btn-primary btn-sm'
            onClick={this.goToOrganizations.bind(this)}
          >Organisations</button>
          <button
            className='nav-action d-none d-lg-block btn btn-primary btn-sm'
            onClick={this.goToUnassignedDevices.bind(this)}
          >Unassigned Devices</button>
        </RoleRequired>

        <ButtonDropdown
          className='nav-action-mobile d-lg-none nav-action'
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          direction='left'
        >
          <DropdownToggle className='btn-sm rounded' color='primary'>
            <i className='fa fa-bars'></i>
          </DropdownToggle>
          <DropdownMenu>
            <RoleRequired user={me} role='SUPER_ADMIN'>
              <DropdownItem onClick={this.goToOrganizations.bind(this)}>
                Organisations
              </DropdownItem>
              <DropdownItem onClick={this.goToUnassignedDevices.bind(this)}>
                Unassigned Devices
              </DropdownItem>
            </RoleRequired>
            <DropdownItem onClick={this.goToReports.bind(this)}>Reports</DropdownItem>
            <DropdownItem onClick={this.goToOrganizationUsers.bind(this)}>Users</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </RoleRequired>
    );
  }
}
