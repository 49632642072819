import React from 'react';


export default ({rows}) => {
  if (rows.length === 0) {
    return <div className='alert alert-warning'>Report is empty.</div>;
  }
  return (
    <table className='table' width='100%'>
      <thead>
        <tr>
          <th>Date/Time</th>
          <th>User</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {
          rows.map((r, i) => (
            <tr key={i} className={r.error ? 'text-danger' : ''}>
              <td style={{'white-space': 'nowrap'}}>{r.ts}</td>
              <td>{r.email}</td>
              <td>{r.event}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
