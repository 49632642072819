import EventEmitter from 'events';
import io from 'socket.io-client';
import Promise from 'bluebird';

import StateInit from './StateInit';
import StateLoading from './StateLoading';
import StateLogin from './StateLogin';

class RemoteServiceError extends Error {
  constructor(msg) {
    super();
    this.name = this.constructor.name;
    this.msg = msg;
    this.stack = (new Error()).stack;
  }
};


export default class RemoteService extends EventEmitter{
  constructor() {
    super();
    this._requestId = 0;
    this._requests = {};
    this._devices = [];
    this._cs = null;
    this._me = null;

    this._socket = io.connect(__WS_SERVER__, {
      path: '/web-api',
      query: 'clientType=user&auth_token=' + localStorage.getItem('auth_token'),
      reconnection: true
    });
    this._socket.on('connect', () => {
      console.log('connected to server');
      this.transitionToState(new StateInit(this));
    });
    this._socket.on('error', (error) => {
      if (error === 'Authentication error') {
        this.transitionToState(new StateLogin(this));
      }
    });
    this._socket.on('resp', (msg) => {
      const {reqId} = msg;
      if (this._requests[reqId]) {
        const {resolve, reject} = this._requests[reqId];
        if (msg.error === true) {
          reject(new RemoteServiceError(msg));
        } else {
          resolve(msg);
        }
        delete this._requests[reqId];
      } else {
        //console.log('resp:', msg);
        console.error('unknown response received', msg);
      }
    });
    this._socket.on('msg', (msg) => {
      //console.log('msg:', msg);
      if (this._cs) {
        this._cs.onMsg(msg);
      }
      if (msg.op === 'xterm') {
        const {deviceId, data} = msg;
        this.emit(`xterm-${deviceId}`, data);
      }
    });
    this._socket.on('disconnect', () => {
      console.log('disconnected from server');
      for (let k in this._requests) {
        this._requests[k].reject(new Error('disconnected'));
      }
      this._requests = {};
      this.transitionToState(new StateLoading(this));
    });
  }
  transitionToState(s) {
    let p = Promise.resolve();
    if (this._cs) {
      p = (this._cs.onUnmount() || Promise.resolve());
    }
    p
      .then(() => {
        this._cs = s;
        return this._cs.onMount();
      })
      .then(() => {
        this.emitState();
      });
    return null;
  }
  emitState() {
    this.emit('change', this._cs.serialize());
  }
  send(cmd = {}) {
    if (this._socket.connected !== true) {
      return console.error('not connected');
    }
    this._socket.emit('msg', cmd);
  }
  request(msg) {
    if (this._socket.connected !== true) {
      console.error('not connected');
      return Promise.reject(new Error('not connected'));
    }
    return new Promise((resolve, reject) => {
      const reqId = this._requestId++;
      this._requests[reqId] = {resolve, reject};
      this._socket.emit('req', {
        ...msg,
        reqId
      });
    });
  }
  setDevices(devices) {
    this._devices = devices;
  }
  getDevices() {
    return this._devices;
  }

  setMe(me) {
    this._me = me;
    this.emitState();
  }
  getMe() {
    return this._me;
  }
  //removeDevice(deviceId) {
  //  if (this._devices.index[deviceId]) {
  //    this._devices.list[this._devices.index[deviceId]].connected = false;
  //  }
  //}
  //addDevice(dev) {
  //  const {deviceId} = dev;
  //  if (!this._devices.index.hasOwnProperty(deviceId)) {
  //    this._devices.index[deviceId] = this._devices.length;
  //    this._devices.list.push({
  //      ...dev,
  //      connected: true
  //    });
  //  } else {
  //    this._devices.list[this._devices.index[deviceId]] = {
  //      ...dev,
  //      connected: true
  //    };
  //  }
  //}
  performStateOperation(op, ...args) {
    if (typeof this._cs[op] === 'function') {
      return this._cs[op].apply(this._cs, args);
    } else {
      console.error(`operation not found: ${op}`, args);
    }
  }
}
