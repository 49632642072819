import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import './index.scss';


export default ({onReboot}) => {
  const onClick = (e) => {
    e.preventDefault();
    if (confirm('Are you sure you wish to reboot this device?') === true) {
      onReboot();
    }
  }
  return (
    <button type='button' className='btn btn-danger' onClick={onClick}>Reboot</button>
  );
}
