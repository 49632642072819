import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Home from '../Home';
import Loading from '../Loading';
import Login from '../Login';
import UnassignedDevices from '../UnassignedDevices';
import UserAccount from '../UserAccount';
import Organizations from '../Organizations';
import OrganizationUsers from '../OrganizationUsers';
import Reports from '../Reports';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = null;
    const {rs} = this.props;
    rs.on('change', (state) => {
      this.setState(state);
    });
  }

  getStateComponent() {
    const {rs, showAdvanced} = this.props;
    if (
      this.state === null ||
      this.state.name === 'STATE_LOADING' ||
      this.state.name === 'STATE_INIT'
    ) {
      return <Loading />;
    } else if (this.state.name === 'STATE_LOGIN') {
      return <Login rs={rs} />;
    } else if (this.state.name === 'STATE_UNASSIGNED_DEVICES') {
      return <UnassignedDevices rs={rs} state={this.state} />;
    } else if (this.state.name === 'STATE_USER_ACCOUNT') {
      return <UserAccount rs={rs} state={this.state} />;
    } else if (this.state.name === 'STATE_ORGANIZATIONS') {
      return <Organizations rs={rs} state={this.state} />;
    } else if (this.state.name === 'STATE_ORGANIZATION_USERS') {
      return <OrganizationUsers rs={rs} state={this.state} />;
    } else if (this.state.name === 'STATE_REPORTS') {
      return <Reports rs={rs} state={this.state} />;
    } else if (this.state.name === 'STATE_HOME') {
      return <Home rs={rs} state={this.state} showAdvanced={showAdvanced} />;
    }
    return <span>{this.state.name}</span>;
  }

  render() {
    const stateComponent = this.getStateComponent();
    return (
      <React.Fragment>
        {stateComponent}
        <ToastContainer position="bottom-right"/>
      </React.Fragment>
    );
  }
}
