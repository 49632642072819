import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import UserForm from './UserForm';
import PasswordForm from '../StyleGuide/PasswordForm';

export default class AddOrganizationUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      userEmail: '',
      userMobile: '',
      userRole: '',
      userPassword: '',
      userPasswordConfirmation: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  stateToUser() {
    let mobile = this.state.userMobile.trim();
    mobile = mobile === '' ? null : mobile;
    return {
      email: this.state.userEmail,
      mobile,
      role: this.state.userRole,
      password: this.state.userPassword,
      passwordConfirmation: this.state.userPasswordConfirmation
    };
  }

  isFormValid() {
    return this.state.userPassword === this.state.userPasswordConfirmation;
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    if (!this.isFormValid()) {
      return;
    }

    this.setState({ loading: true });

    rs.request({ op: 'addOrganizationUser', user: this.stateToUser() })
      .then(() => {
        this.setState({ loading: false });
        toast.success('User was added successfully!')
        this.toggle();
        return rs.performStateOperation('getOrganizationUsers');
      })
      .catch(error => {
        this.setState({ loading: false })
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      userEmail: '',
      userMobile: '',
      userRole: '',
      userPassword: '',
      userPasswordConfirmation: ''
    });
  }

  render() {
    const me = this.props.rs.getMe();
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary add-organization-user'
          onClick={() => this.toggle()}
        >Add User</button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size='md'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Add User
            </ModalHeader>
            <ModalBody>
              <UserForm
                {...this.state}
                canAddSuperAdmin={me.role === 'SUPER_ADMIN'}
                handleChange={this.handleChange}
              />
              <PasswordForm
                {...this.state}
                handleChange={this.handleChange}
              />
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-primary mr-2'
              >{this.state.loading ? 'Saving...' : 'Save'}</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
