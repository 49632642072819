import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import './index.scss';
import logo from '../assets/logo.png';


export default class Loading extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100 bg-blue loading'>
        <div className='h2 text-info mb-3'>
          <span className='fas fa-spinner fa-spin' />
        </div>
        <div className='text-info'>Please wait...</div>
        <img className='logo' src={logo} />
      </div>
    );
  }
}
