import React from 'react';
import { toast } from 'react-toastify';

import './index.scss';

export default class Alarms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 1,
      showCleared: false,
      alarms: []
    };

    this.toggleCleared = this.toggleCleared.bind(this);
  }

  componentDidMount() {
    this.getAlarms();
  }

  selectDevice(deviceId, e) {
    e.preventDefault();
    this.props.onSelectDevice(deviceId)
  }

  toggleCleared() {
    this.setState({ showCleared: !this.state.showCleared }, () => {
      this.getAlarms();
    });
  }

  getAlarms() {
    const {page, showCleared} = this.state;
    this.setState({loading: true});
    this.props.getAlarms(page, showCleared)
      .then(({ alarms }) => {
        this.setState({
          alarms,
          loading: false
        })
      })
      .catch(error => {
        this.setState({ loading: false });
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  clearAlarm(alarmId, e) {
    e.preventDefault();

    this.setState({
      alarms: this.state.alarms.map((alarm) => {
        return alarm.id === alarmId ? { ...alarm, loading: true } : alarm;
      })
    });

    this.props.clearAlarm(alarmId)
      .then(() => {
        this.setState({
          alarms: this.state.alarms.filter(alarm => alarm.id != alarmId)
        });
        toast.success('Alarm cleared successfully!');
      })
      .catch(error => {
        this.setState({
          alarms: this.state.alarms.map((alarm) => {
            return alarm.id === alarmId ? { ...alarm, loading: false } : alarm;
          })
        });
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  pageUp() {
    this.setState({
      page: this.state.page + 1
    }, () => this.getAlarms());
  }

  pageDown() {
    this.setState({
      page: this.state.page - 1
    }, () => this.getAlarms());
  }

  render() {
    const {canClearAlarms} = this.props;
    const alarms = this.state.alarms.map(alarm => (
      <tr key={alarm.id}>
        <td>{alarm.ts}</td>
        <td>
          <a
            href='#'
            onClick={this.selectDevice.bind(this, alarm.device_id)}
          >{alarm.device_name}</a>
        </td>
        <td>{alarm.description}</td>
        <td>
          { !alarm.cleared && canClearAlarms &&
            <a
              href='#'
              onClick={this.clearAlarm.bind(this, alarm.id)}
            >{alarm.loading ? (<i className="fa fa-spin fa-spinner"></i>) : 'clear'}</a>
          }
        </td>
      </tr>
    ));

    if (!alarms.length) {
      alarms.push((
        <tr key="there-are-no-alarms">
          <td colSpan="4">There are no alarms</td>
        </tr>
      ));
    }

    return (
      <div className="row alarms">
        <div className="col-12 alarms-header">
          <div className="alarms-title">
            <h1>Alerts</h1>
          </div>
          <div className="btn-group">
            <button className="btn btn-link"
              onClick={this.pageDown.bind(this)}
              disabled={this.state.loading || this.state.page === 1}>
              {'<'}
            </button>
            <button className="btn">{this.state.page}</button>
            <button className="btn btn-link"
              onClick={this.pageUp.bind(this)}
              disabled={this.state.loading || this.state.alarms.length <= 0}>
              {'>'}
            </button>
          </div>
          <div className="alarms-cleared-toggle">
            <button className="btn btn-secondary btn-sm" onClick={this.toggleCleared}>
              { this.state.showCleared ? 'Hide Cleared' : 'Show Cleared'}
            </button>
          </div>
        </div>

        <div className="col-12">
          {
            this.state.loading ?
            <span>Loading alarms ...</span> :
            (
              <table className="table">
                <tbody>
                  <tr key="table-header">
                    <th>Date/Time</th>
                    <th>Device</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                  {alarms}
                </tbody>
              </table>
            )
          }
        </div>
      </div>
    );
  }
};
