import React from 'react';

import './index.scss';
import StyleGuide from '../StyleGuide';
import AddOrganizationUserForm from './AddOrganizationUserForm';
import EditOrganizationUserForm from './EditOrganizationUserForm';
import ResetPasswordOrganizationUserForm from './ResetPasswordOrganizationUserForm';
import DeleteOrganizationUserForm from './DeleteOrganizationUserForm';

export default class OrganizationUsers extends React.Component {
  render() {
    const { rs, state } = this.props;
    const me = rs.getMe();

    const users = state.users.map(user => {
      return (
        <tr key={user.id}>
          <th scope="row">{user.id}</th>
          <td>{user.email}</td>
          <td>{user.role}</td>
          <td>
            <div className="btn-group" role="group">
              <React.Fragment>
                <EditOrganizationUserForm user={user} rs={rs}/>
                <ResetPasswordOrganizationUserForm user={user} rs={rs}/>
                <DeleteOrganizationUserForm user={user} rs={rs}/>
              </React.Fragment>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <StyleGuide.Layout rs={rs} state={state}>
        <div className="organization-users-page container">
          <div className="row">
            <div className="col-12">
              <AddOrganizationUserForm rs={rs}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Role</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </StyleGuide.Layout>
    );
  }
}
