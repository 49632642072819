import React from 'react';

import mapStateToReservoirLevel from './map-state-to-reservoir-level';
import Track from './Track';

export default class Status extends React.Component {
  constructor(props) {
    super(props);
    this.updateDeviceState = this.updateDeviceState.bind(this);
  }

  componentDidMount() {
    const {rs} = this.props;

    this.timer = setInterval(() => {
      rs.performStateOperation('selectDevice', this.props.device.deviceId);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateDeviceState(patches) {
    const { rs, device } = this.props;
    return rs.performStateOperation('updateDeviceState', device.deviceId, patches);
  }

  render() {
    const { device, rs } = this.props;
    const { state } = device;
    const {reservoirLevel, reservoirDaysLeft, strokeSize} = mapStateToReservoirLevel(state);
    const me = rs.getMe();
    const editable = me && me.role === 'ADMIN' && state.dbState && state.dbState.softwareVersion;
    const commonTrackProps = { editable, updateDeviceState: this.updateDeviceState, strokeSize };

    return (
      <div className="device-status">
        <div className='row mb-3'>
          <div className='col-lg-3 col-6'>Temp: {state.temperature}</div>
          <div className='col-lg-3 col-6'>Battery: {state.batteryVoltage}V</div>
          <div className='col-lg-3 col-6'>Solar: {state.solarVoltage}V</div>
          <div className='col-lg-3 col-6'>Reservoir: {reservoirLevel} - {reservoirDaysLeft}</div>
        </div>
        <div className='row'>
          <Track number={1} track={state.track_1} fault={state.faults.track_1} {...commonTrackProps} />
          <Track number={2} track={state.track_2} fault={state.faults.track_2} {...commonTrackProps} />
          <Track number={3} track={state.track_3} fault={state.faults.track_3} {...commonTrackProps} />
          <Track number={4} track={state.track_4} fault={state.faults.track_4} {...commonTrackProps} />
        </div>
      </div>
    );
  }
}
