import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import Map from '../Map';

export default class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      coords: null
    };

    this.latRef = React.createRef();
    this.longRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMapUpdate = this.handleMapUpdate.bind(this);
    this.handleMapSet = this.handleMapSet.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const {device} = this.props;
    let position = null;
    if (device && device.position) {
      const {x, y} = device.position;
      position = {latitude: x, longitude: y};
    }
    this.setState({
      modal: !this.state.modal,
      coords: position
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {coords} = this.state;
    if (coords === null) return;

    const {device, onSave} = this.props;
    const {latitude, longitude} = coords;

    this.setState({ loading: true });
    onSave(device.deviceId, {latitude, longitude})
      .then(() => {
        toast.success('Device position updated successfully!')
        this.toggle();
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  handleMapUpdate() {
    this.setState({
      ...this.state,
      loading: true,
      coords: null
    });
    navigator.geolocation.getCurrentPosition(
      pos => {
        this.latRef.current.value = pos.coords.latitude;
        this.longRef.current.value = pos.coords.longitude;
        this.setState({
          ...this.state,
          loading: false,
          coords: pos.coords
        });
      },
      err => {
        this.setState({
          ...this.state,
          loading: false
        });
      }
    );
  }

  handleMapSet() {
    const lat = this.latRef.current.value;
    const long = this.longRef.current.value;
    if (lat && long) {
      this.setState({
        ...this.state,
        loading: false,
        coords: {
          latitude: this.latRef.current.value,
          longitude: this.longRef.current.value
        }
      });
    }
  }

  render() {
    const {
      modal,
      loading,
      coords
    } = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          title='Change device location'
          className='btn btn-outline-dark btn-sm mr-2'
          onClick={() => this.toggle()}
        >
          <i className='fas fa-map-marked-alt' />
        </button>
        <Modal isOpen={modal} toggle={() => this.toggle()} size='lg' className='device-location'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Device location
            </ModalHeader>
            <ModalBody>
              <div className='mb-2'>
                <Map coords={coords} />
              </div>
              <div className='d-flex flex-row'>
                <button
                  type='button'
                  className='btn btn-primary mr-2'
                  disabled={loading}
                  onClick={this.handleMapUpdate}
                >
                  <i className='fas fa-location-arrow' />
                </button>
                <div className='input-group mr-auto w-40'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='latitude'
                    ref={this.latRef}
                    defaultValue={coords === null ? '' : coords.latitude}
                    disabled={loading || coords === null}
                  />
                  <input
                    type='text'
                    className='form-control'
                    placeholder='longitude'
                    ref={this.longRef}
                    defaultValue={coords === null ? '' : coords.longitude}
                    disabled={loading || coords === null}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={this.handleMapSet}
                      disabled={loading || coords === null}
                    >Set</button>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-primary mr-2'
                onClick={this.handleSubmit}
                disabled={loading || coords === null}
              >Save</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
