import React from 'react';
import L from 'leaflet';
import {Map as LeafletMap, TileLayer, Marker} from 'react-leaflet';

import './index.scss';

import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow
});


export default ({coords}) => {
  let marker = [-33.8, 151.2];
  if (coords !== null) {
    const {latitude: lat, longitude: lng} = coords;
    marker = [lat, lng];
  }
  return (
    <LeafletMap center={marker} zoom={16}>
      <TileLayer
        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {coords !== null && <Marker position={marker} />}
    </LeafletMap>
  );
};
