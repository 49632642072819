export default (mode) => {
  switch (mode) {
    case 'accumulating':
      return 'acc'
      break;
    case 'resetting':
      return 'reset'
      break;
    default:
      return 'off'
  }
};
