import Promise from 'bluebird';
import React from 'react';

import './index.scss';
import Device from '../Device';
import DeviceList from '../DeviceList';
import StyleGuide from '../StyleGuide';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceListVisible: false
    }
  }

  toggleDeviceList(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      deviceListVisible: !this.state.deviceListVisible
    });
  }

  getAlarms(page, showCleared) {
    const {rs} = this.props;
    return rs.performStateOperation('getAlarms', page, showCleared);
  }

  clearAlarm(alarmId) {
    const {rs} = this.props;
    return rs.performStateOperation('clearAlarm', alarmId);
  }

  onDeleteDevice(deviceId) {
    const {rs} = this.props;
    return rs.performStateOperation('deleteDevice', deviceId);
  }

  onSaveDeviceName(deviceId, deviceName) {
    const {rs} = this.props;
    rs.performStateOperation('updateDeviceName', deviceId, deviceName);
  }

  onSaveDevicePosition(deviceId, position) {
    const {rs} = this.props;
    return rs.performStateOperation('updateDevicePosition', deviceId, position);
  }

  onSaveDeviceSettings(deviceId, dbState) {
    const {rs} = this.props;
    return rs.performStateOperation('updateDeviceState', deviceId, [{dbState}]);
  }

  onSelectView(view) {
    const {rs} = this.props;
    rs.performStateOperation('selectView', view);
  }

  onSelectDevice(deviceId) {
    const {rs} = this.props;
    this.setState({
      ...this.state,
      deviceListVisible: false
    });
    rs.performStateOperation('selectDevice', deviceId);
  }

  getDeviceEvents(page) {
    const {rs} = this.props;
    rs.performStateOperation('getDeviceEvents', page);
  }

  getDeviceLogs(page) {
    const {rs} = this.props;
    rs.performStateOperation('getDeviceLogs', page);
  }

  getDeviceUsers() {
    const {rs} = this.props;
    rs.performStateOperation('getDeviceUsers');
  }

  toggleDeviceUser(userId, enabled) {
    const {rs} = this.props;
    return rs.performStateOperation('toggleDeviceUser', userId, enabled);
  }

  onRebootDevice() {
    const {rs} = this.props;
    rs.performStateOperation('rebootDevice');
  }

  render() {
    const {rs, state, showAdvanced} = this.props;
    return (
      <StyleGuide.Layout rs={rs} state={state} >
        <div className="container-fluid home-page">
          <div className="row">
            <div className={`col-12 device-list-toggle`}>
              <a href="#" onClick={this.toggleDeviceList.bind(this)}>
                <i className={`fa fa-lg ${this.state.deviceListVisible ? 'fa-times' : 'fa-bars'}`} />
              </a>
            </div>

            <div className={`col-12 col-lg-3 device-list-container device-list ${this.state.deviceListVisible ? 'device-list-toggle-on' : 'device-list-toggle-off'}`} >
              <DeviceList state={state} onSelectDevice={this.onSelectDevice.bind(this)}/>
            </div>

            <div className="col-12 col-lg-9 pt-3 content">
              <Device
                rs={rs}
                state={state}
                showAdvanced={showAdvanced}
                getAlarms={this.getAlarms.bind(this)}
                clearAlarm={this.clearAlarm.bind(this)}
                getEvents={this.getDeviceEvents.bind(this)}
                getLogs={this.getDeviceLogs.bind(this)}
                getDeviceUsers={this.getDeviceUsers.bind(this)}
                toggleDeviceUser={this.toggleDeviceUser.bind(this)}
                onSaveDeviceName={this.onSaveDeviceName.bind(this)}
                onSaveDevicePosition={this.onSaveDevicePosition.bind(this)}
                onSaveDeviceSettings={this.onSaveDeviceSettings.bind(this)}
                onDeleteDevice={this.onDeleteDevice.bind(this)}
                onSelectView={this.onSelectView.bind(this)}
                onSelectDevice={this.onSelectDevice.bind(this)}
                onReboot={this.onRebootDevice.bind(this)}
              />
            </div>
          </div>
        </div>
      </StyleGuide.Layout>
    );
  }
}
