import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import {Terminal as XTerm} from 'xterm';

import './index.scss';


export default class Terminal extends React.Component {
  constructor(opts) {
    super(opts);
    this.container = null;
    this.xterm = new XTerm({
      cols: 80,
      rows: 30,
      cursorBlink: true
    });

    this.updateRemoteService = (data) => {
      const {device, rs} = this.props;
      rs.send({
        op: 'xterm',
        deviceId: device.deviceId,
        data
      });
    }
    this.updateTerminal = (data) => {
      this.xterm.write(data);
    }
  }

  componentDidMount() {
    const {device, rs} = this.props;
    this.xterm.open(this.container);
    this.xterm.on('data', this.updateRemoteService);
    rs.on(`xterm-${device.deviceId}`, this.updateTerminal);
  }
  componentWillUnmount() {
    const {device, rs} = this.props;
    this.xterm.off('data', this.updateRemoteService);
    rs.removeListener(`xterm-${device.deviceId}`, this.updateTerminal);
    this.xterm.destroy();
    this.xterm = null;
  }

  render() {
    const {device} = this.props;
    const {state} = device;
    const {dbState} = state;

    let softwareVersion = 'N/A';
    let teamviewerId = 'N/A';
    if (dbState) {
      if (dbState.teamviewerId) {
        teamviewerId = dbState.teamviewerId;
      }
      if (dbState.softwareVersion) {
        softwareVersion = dbState.softwareVersion;
      }
    }

    return (
      <div>
        <div className='d-flex flex-row justify-content-between mb-2'>
          <div>Software version: {softwareVersion}</div>
          <div>TID: {teamviewerId}</div>
        </div>
        <div ref={ref => (this.container = ref)} />
      </div>
    );
  }
}
