import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import RemoteService from './RemoteService';
import App from './App';

const url = new URLSearchParams(document.location.search);
const showAdvanced = url.get('show-advanced') === 'true';

const rs = new RemoteService();
ReactDOM.render(
  <App rs={rs} showAdvanced={showAdvanced} />,
  document.getElementById('app')
);

module.hot && module.hot.accept();
