import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class AddOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      organizationName: '',
      adminEmail: '',
      adminPassword: '',
      adminPasswordConfirmation: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  isPasswordConfirmationWrong() {
    return this.state.adminPasswordConfirmation
      && this.state.adminPassword !== this.state.adminPasswordConfirmation;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    rs.performStateOperation('addOrganization', {
      name: this.state.organizationName,
      adminEmail: this.state.adminEmail,
      adminPassword: this.state.adminPassword,
      adminPasswordConfirmation: this.state.adminPasswordConfirmation
    });

    this.toggle();
  }

  toggle() {
    const organization = this.props.organization;

    this.setState({
      modal: !this.state.modal,
      organizationName: '',
      adminEmail: '',
      adminPassword: '',
      adminPasswordConfirmation: ''
    });
  }

  adminGroups() {
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-primary add-organization" onClick={() => this.toggle()}>
          Add Organization
        </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size="md">
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Add Organization
            </ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label forhtml="organization-name">Name</label>
                <input
                  type="text" autoFocus className="form-control"
                  id="organization-name" name="organizationName"
                  value={this.state.organizationName} onChange={this.handleChange}
                  required/>
              </div>
              <div className="form-group">
                <label forhtml="admin-email">Email</label>
                <input
                  type="email" className="form-control"
                  id="admin-email" name="adminEmail" value={this.state.adminEmail}
                  onChange={this.handleChange} required autoComplete="email"/>
              </div>
              <div className="form-group">
                <label forhtml="admin-password">Password</label>
                <input
                  type="password" className="form-control" id="admin-password"
                  name="adminPassword" value={this.state.adminPassword}
                  onChange={this.handleChange} required autoComplete="new-password"/>
              </div>
              <div className="form-group">
                <label forhtml="admin-password-confirmation">Repeat Password</label>
                <input
                  type="password" className={`form-control ${this.isPasswordConfirmationWrong() ? 'is-invalid' : ''}`}
                  id="admin-password-confirmation" name="adminPasswordConfirmation"
                  value={this.state.adminPasswordConfirmation} onChange={this.handleChange}
                  required autoComplete="confirm-password"/>
                <div className="invalid-feedback">
                  Password does not match!
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-primary">Save</button>{' '}
              <button type="button" className="btn btn-secondary" onClick={() => this.toggle()}>Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
