import React from 'react';

import './index.scss';

// Function to convert HSV to RGB
function hsvToRgb(h, s, v) {
  let f = (n, k = (n + h / 60) % 6) =>
      v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5) * 255, f(3) * 255, f(1) * 255];
}

// Convert HSV to RGB color string
function hsvToRgbString(h, s, v) {
  const [r, g, b] = hsvToRgb(h, s, v);
  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
}

export default class Connectivity extends React.Component {
  refreshTimeout = 0;

  constructor(opts) {
    super(opts);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      image: null,
      error: null,
      seen: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.refreshTimeout)
      clearTimeout(this.refreshTimeout);
  }

  dateFromUnixHour(uh) {
    return new Date(uh * 1000 * 3600);
  }

  timestampFromUnixHour(uh) {
    let date = this.dateFromUnixHour(uh);
    let hour = date.getHours();
    return String(hour).padStart(2, '0');
  }

  processSamples(lastSeen, samples, fullWidth, fullHeight, padding) {
    const canvas = document.createElement('canvas');
    canvas.width = fullWidth;
    canvas.height = fullHeight;

    const width = fullWidth - padding - padding;
    const height = fullHeight - padding - padding;

    const ctx = canvas.getContext('2d');

    // Fill the whole area with a washed out, red, "no records" colour
    ctx.fillStyle = hsvToRgbString(0 * 120, 0.3, 0.8);
    // no right padding
    ctx.fillRect(padding, padding, width, height - 16);

    ctx.fillStyle = '#000';

    // The chart is 24 5-minute intervals
    const now = Date.now();
    let unixHourNow = now / 3600e3;
    let unixHourLow = unixHourNow - 24;
    let barW = width / 24;

    ctx.strokeStyle = "#555";
    ctx.lineWidth = 2;

    const sampleLookup = new Map(samples.map((sample) => {
      return [sample.unixhour, sample];
    }));

    const pingsPerHour = 12;

    // Divide the area into bands
    for (let pass = 0; pass < 2; ++pass) {
      if (pass > 0) {
        ctx.beginPath();
        ctx.rect(0, 0, fullWidth, fullHeight);
        ctx.clip();

        // Shift x position to align with 24-hour window
        for (let i = -1; i <= 24; ++i) {
          const unixhour = Math.ceil(unixHourLow + i);
          const sampleDate = this.dateFromUnixHour(unixhour);
          const midnight = sampleDate.getHours() === 0;
          const noon = sampleDate.getHours() === 12;
          let sx = unixhour - unixHourLow;
          let ex = sx + 1;
          sx *= barW;
          ex *= barW;
          let sy = 0;
          let ey = height - 16;
          sx += padding;
          ex += padding;
          sy += padding;
          ey += padding;

          let expectedPings = pingsPerHour;

          if (unixhour === Math.floor(unixHourNow)) {
            // Calculate how many 5-minute
            // intervals have passed in the current hour
            const elapsedMinutes = (Date.now() / 60000) % 60;
            const fiveMinuteIntervalsPassed = Math.floor(elapsedMinutes / 5);
            // Perfect case: 1 ping every 5 minutes
            expectedPings = fiveMinuteIntervalsPassed;
          }

          ctx.strokeStyle = "#555";
          ctx.lineWidth = midnight ? 1.5 : 2;
          ctx.setLineDash(noon ? [8, 8] : midnight ? [] : [2, 1]);
          if (!midnight) {
            ctx.beginPath();
            ctx.moveTo(sx, sy);
            ctx.lineTo(sx, ey);
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(sx + 1.5, sy);
            ctx.lineTo(sx + 1.5, ey);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(sx - 1.5, sy);
            ctx.lineTo(sx - 1.5, ey);
            ctx.stroke();
          }
          ctx.setLineDash([]);

          // Black text
          ctx.textAlign = 'center';
          ctx.textBaseline = 'top';
          ctx.fillStyle = '#000';
          ctx.font = '12px Arial';
          const timestamp = this.timestampFromUnixHour(unixhour);
          ctx.fillText(timestamp, sx, height - 6);

          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '12px Arial';

          const sample = sampleLookup.get(unixhour);
          const h = sample ? sample.ping_count / expectedPings : 0;
          ctx.fillText(Math.floor(Math.min(100, h * 100)) + '%',
            sx + (barW / 2), height * 0.5);
        }
        continue;
      }
      ctx.beginPath();
      ctx.rect(padding, padding, width, height);
      ctx.clip();

      samples.forEach((sample) => {
        const sampleDate = this.dateFromUnixHour(sample.unixhour);
        const midnight = sampleDate.getHours() === 0;
        const noon = sampleDate.getHours() === 12;

        // Shift x position to align with 24-hour window
        let sx = sample.unixhour - unixHourLow;
        let ex = sx + 1;
        sx *= barW;
        ex *= barW;
        let sy = 0;
        let ey = height - 16;
        sx += padding;
        ex += padding;
        sy += padding;
        ey += padding;

        let expectedPings = pingsPerHour; // Default expected pings per hour

        if (sample.unixhour === Math.floor(unixHourNow)) {
          // Calculate how many 5-minute intervals have passed in the current hour
          const elapsedMinutes = (Date.now() / 60000) % 60;
          const fiveMinuteIntervalsPassed = Math.floor(elapsedMinutes / 5);
          // Perfect case: 1 ping every 5 minutes
          expectedPings = fiveMinuteIntervalsPassed;
        }

        // Calculate the proportion of pings relative to expected pings
        // Avoid division by zero
        let h = sample.ping_count / Math.max(expectedPings, 1);

        // 0=red, 60=yellow (50%), 120=green (100%)
        h = Math.min(1.0, h);

        const dark = hsvToRgbString(h * 120, 0.7, 0.70);
        const light = hsvToRgbString(h * 120, 0.7, 0.9);
        const mid = sy + (ey - sy) * (1 - h);
        ctx.fillStyle = dark;
        ctx.fillRect(sx, sy, ex - sx, mid);
        ctx.fillStyle = light;
        ctx.fillRect(sx, mid, ex - sx, ey - mid);
      });
    }

    ctx.beginPath();
    ctx.rect(padding, padding, width, height - 16);
    ctx.clip();
    ctx.strokeStyle = "#555";
    ctx.lineWidth = 2;
    ctx.strokeRect(padding, padding, width, height - 16);

    ctx.beginPath();
    ctx.rect(0, 0, fullWidth, fullHeight);
    ctx.clip();

    this.setState({
      pingId: 0,
      image: canvas.toDataURL('image/png'),
      seen: lastSeen,
      error: null
    });
  }

  fetchPingId() {
    const { deviceName } = this.props;

    try {
      return fetch('/api/connectivity/find/' +
        encodeURIComponent(deviceName))
      .then((response) => {
        if (response.status >= 400)
          throw new Error(response.text());
        return response.json().then((obj) => {
          this.setState({
            pingId: obj.id
          }, () => {
            // Get data right away after getting ping id
            this.fetchData();
          });
        });
      }).catch((err) => {
        console.log('pingId fetch error:', err);
        // done
      });
    } catch (err) {
      console.warn('pingId fetch error:', err);
    }
  }

  fetchData() {
    // Arrow functions don't work
    const self = this;

    const {deviceName} = self.props;
    if (!self.state.pingId) {
      return self.fetchPingId();
    }
    // We get one sample per 5 minutes
    return fetch('/api/connectivity/query/' +
      encodeURIComponent(self.state.pingId))
    .then((response) => {
      if (response.status < 400) {
        return response.json().then((data) => {
          const seen = data ? data.seen : null;
          // Handle array at top level for backward compatibility
          const samples = (data && Array.isArray(data))
            ? data
            : data
            ? data.rows
            : null;
          self.processSamples(seen, samples, 1024, 192, 8);
        }).catch((err) => {
          self.setState({
            error: err
          });
        });
      } else {
        response.text().then((err_text) => {
          self.setState({
            error: err_text
          });
        });
      }
    }).finally(() => {
      setTimeout(self.fetchData, 5 * 60e3);
    });
  }

  render() {
    const {deviceName} = this.props;
    if (this.state.error !== null) {
      return <span>{this.state.error}</span>;
    }
    if (this.state.pingId === null) {
      return <span>No records</span>;
    }
    if (this.state.image === null) {
      return <span>Loading...</span>;
    }
    return (
      <table className='w-100' style={{
        maxWidth: '1024px'
      }}>
        <thead>
          <tr>
            <th>
              <span>Connectivity</span>
              <div style={{float: 'right', fontWeight: 'normal'}}>
                Last Seen:
                {((d) => {
                  const now = Date.now();
                  const secsAgo = (now - d.getTime()) / 1000;
                  let remain = secsAgo;
                  const daysAgo = Math.floor(remain / 86400);
                  remain -= daysAgo * 86400;
                  const hoursAgo = Math.floor(remain / 3600);
                  remain -= hoursAgo * 3600;
                  const minAgo = Math.floor(remain / 60);
                  remain -= minAgo * 60;
                  const secAgo = Math.floor(remain);
                  remain -= secAgo;
                  console.assert(remain < 1);
                  const pieces = [
                    daysAgo + ' days',
                    hoursAgo + ' hours',
                    minAgo + ' minutes'
                  ];
                  while (pieces[0] && pieces[0].startsWith('0 '))
                    pieces.shift();
                  const ago = (pieces.length > 1 || minAgo > 10)
                    ? pieces.join(', ') + ' ago'
                    : 'recently';
                  const whenText = ' ' +
                    d.getFullYear().toString().padStart(4, '0') + '-' +
                    (d.getMonth() + 1).toString().padStart(2, '0') + '-' +
                    (d.getDate().toString().padStart(2, '0')) + ' ' +
                    d.getHours().toString().padStart(2, '0') + ':' +
                    d.getMinutes().toString().padStart(2, '0') + ':' +
                    d.getSeconds().toString().padStart(2, '0');
                  const whenMessage = (pieces.length == 1 && minAgo < 10)
                    ? ago
                    : (whenText + ' ' + ago);
                  return ' ' + whenMessage;
                })(new Date(this.state.seen * 1000))}
              </div>
              <div style={{clear:'left'}}></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>
              <img src={this.state.image}/>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
