import State from './State';
import StateHome from './StateHome';
import StateLogin from './StateLogin';


export default class StateInit extends State {
  constructor(rs) {
    super(rs);
  }
  serialize() {
    return {
      name: 'STATE_INIT'
    };
  }
  onMount() {
    this.rs.request({
      op: 'me',
      token: localStorage.getItem('auth_token')
    })
      .then(({ me }) => {
        this.rs.setMe(me);
      })
      .catch(e => {
        alert('failed to get me');
        console.log(e);
      })

    this.rs.request({op: 'getDeviceList'})
      .then(({devices}) => {
        this.rs.setDevices(devices);
        this.rs.transitionToState(new StateHome(this.rs));
      });
    return null;
  }
}
