import React from 'react';
import classnames from 'classnames';

import EditTrack from './EditTrack';
import modeLabel from './mode-label';

const OnOff = ({ value }) => {
  const label = value === true ? 'on' : 'off';
  return (<span>{label}</span>);
};

const OpMode = ({ mode }) => {
  const label = modeLabel(mode);
  return (<span>{label}</span>);
};

export default class Track extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      1: false,
      2: false,
      3: false,
      4: false
    };
  }

  clearFault(number, e) {
    e.preventDefault();
    this.props.updateDeviceState([{
      control: {
        [`reset_track_${number}_fault`]: true
      }
    }]);
    this.setState({
      ...this.state,
      [number]: true
    });
  }

  render() {
    const {number, track, fault, editable, strokeSize} = this.props;
    return (
      <div className='col-12 col-lg-6 pt-3 pb-3'>
        <div className={classnames('card p-2', {'alert-danger': fault})}>
          <div className='d-flex justify-content-between mb-2'>
            {
              editable ?
              (
                <strong>
                  <EditTrack
                    number={number}
                    track={track}
                    updateDeviceState={this.props.updateDeviceState}
                  /> Track {number}
                </strong>
              ) :
              <strong>Track {number}</strong>
            }
            {
              editable && fault ?
              (
                <button
                  type="button"
                  className="btn btn-danger btn-sm track-edit-btn"
                  onClick={this.clearFault.bind(this, number)}
                  disabled={this.state[number]}
                >Clear fault</button>
              ) :
              null
            }
          </div>
          <div className='row'>
            <div className='col-3 offset-6 text-center'>U</div>
            <div className='col-3 text-center'>D</div>
          </div>
          <div className='row'>
            <div className='col-6'>MODE</div>
            <div className='col-3 text-center'>
              <OpMode mode={track.mode.upOpMode}/>
            </div>
            <div className='col-3 text-center'>
              <OpMode mode={track.mode.downOpMode}/>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>CYCLING</div>
            <div className='col-3 text-center'>
              <OnOff value={track.mode.upCycling} />
            </div>
            <div className='col-3 text-center'>
              <OnOff value={track.mode.downCycling} />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>TRIGGER</div>
            <div className='col-3 text-center'>{track.upTriggerCount}</div>
            <div className='col-3 text-center'>{track.downTriggerCount}</div>
          </div>
          <div className='row'>
            <div className='col-6'>PUMP TIME</div>
            <div className='col-3 text-center'>{track.upPumpTime}</div>
            <div className='col-3 text-center'>{track.downPumpTime}</div>
          </div>
          <div className='row'>
            <div className='col-6'># AXLES</div>
            <div className='col-3 text-center'>{track.totalUpCount}</div>
            <div className='col-3 text-center'>{track.totalDownCount}</div>
          </div>
          <div className='row pt-3'>
            <div className='col-9 font-weight-bold'>TOTAL # AXLES</div>
            <div className='col-3 text-right'>{track.totalUpCount + track.totalDownCount}</div>
          </div>
          <div className='row'>
            <div className='col-9 font-weight-bold'>TOTAL GREASE DISPENSED</div>
            <div className='col-3 text-right'>{(track.totalStrokeCount * strokeSize / 10000).toFixed(2)}L</div>
          </div>
        </div>
      </div>
    );
  }
};
