import State from './State';
import { toast } from 'react-toastify';

export default class StateUserAccount extends State {
  constructor(rs) {
    super(rs);
  }
  serialize() {
    return {
      name: 'STATE_USER_ACCOUNT'
    };
  }

  resetUserPassword(password) {
    return this.rs.request({ op: 'resetUserPassword', password })
      .then(() => {
        toast.success('Password was reseted successfully!');
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }
}
