import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import './index.scss';


export default class Logs extends React.Component {
  constructor(opts) {
    super(opts);
  }

  componentDidMount() {
    this.props.getLogs(0);
  }

  scrollPage(inc, e) {
    e.preventDefault();
    const {getLogs, logs} = this.props;
    const {page, rows} = logs;
    const nextPage = page + inc;
    if (nextPage >= 0 && (rows.length || inc < 0)) {
      getLogs(nextPage);
    }
  }

  renderHeader(page) {
    return (
      <div className='row'>
        <div className='col-3 d-flex align-items-center font-weight-bold'>Date</div>
        <div className='col-7 d-flex align-items-center font-weight-bold'>Details</div>
        <div className='col-2'>
          <div className='btn-group'>
            <button
              className='btn btn-link'
              onClick={this.scrollPage.bind(this, -1)}
            >&lt;</button>
            <button
              className='btn'
            >{page + 1}</button>
            <button
              className='btn btn-link'
              onClick={this.scrollPage.bind(this, 1)}
            >&gt;</button>
          </div>
        </div>
      </div>
    );
  }

  renderRows(logs) {
    return logs.map(({ts, payload}, i) => (
      <div className='row' key={i}>
        <div className='col-3'>{ts}</div>
        <div className='col-9 text-truncate'>{payload}</div>
      </div>
    ));
  }

  render() {
    const {logs} = this.props;
    if (logs === null) {
      return <span>loading device logs...</span>;
    }
    const header = this.renderHeader(logs.page);
    const rows = this.renderRows(logs.rows);
    return (
      <div>
        {header}
        {rows}
      </div>
    );
  }
}
