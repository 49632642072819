import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import UserForm from './UserForm';

export default class EditOrganizationUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      userEmail: this.props.user.email,
      userMobile: this.props.user.mobile !== null ? this.props.user.mobile : '',
      userRole: this.props.user.role,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  stateToUser() {
    let mobile = this.state.userMobile.trim();
    mobile = mobile === '' ? null : mobile;
    return {
      id: this.props.user.id,
      email: this.state.userEmail,
      mobile,
      role: this.state.userRole
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;
    this.setState({ loading: true });

    rs.request({ op: 'updateOrganizationUser', user: this.stateToUser() })
      .then(() => {
        this.setState({ loading: false });
        toast.success('User was update successfully!')
        this.toggle();
        return rs.performStateOperation('getOrganizationUsers');
      })
      .catch(error => {
        this.setState({ loading: false })
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const {rs} = this.props;
    const me = rs.getMe();
    return (
      <React.Fragment>
        <button type="button" className="btn btn-info btn-sm" onClick={() => this.toggle()}>
          <i className="fa fa-edit"></i>
        </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size='md'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Edit User
            </ModalHeader>
            <ModalBody>
              <UserForm
                {...this.state}
                canAddSuperAdmin={me.role === 'SUPER_ADMIN'}
                handleChange={this.handleChange}
              />
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-primary mr-2'
              >{this.state.loading ? 'Saving...' : 'Save'}</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
