import State from './State';
import { toast } from 'react-toastify';

export default class StateHome extends State {
  constructor(rs) {
    super(rs);
    this.device = 'none';
    this.events = null;
    this.logs = null;
    this.users = null;
    this.view = 'status';
  }
  serialize() {
    return {
      name: 'STATE_HOME',
      devices: this.rs.getDevices(),
      device: this.device,
      events: this.events,
      logs: this.logs,
      users: this.users,
      view: this.view
    };
  }
  getAlarms(page, showCleared) {
    const LIMIT = 25;
    return this.rs.request({
      op: 'getAlarms',
      showCleared,
      offset: (page - 1) * LIMIT,
      limit: LIMIT
    })
      .catch(e => {
        alert('failed to get alarms');
        console.error(e);
      });
  }
  clearAlarm(alarmId) {
    return this.rs.request({
      op: 'clearAlarm',
      alarmId
    })
      .catch(e => {
        alert('failed to get alarms');
        console.error(e);
      });
  }
  getDeviceLogs(page) {
    const LIMIT = 25;
    this.logs = null;
    if (this.device && this.device !== 'none') {
      const {deviceId} = this.device;
      this.rs.request({
        op: 'getDeviceLogs',
        deviceId,
        offset: page * LIMIT,
        limit: LIMIT
      })
        .then(({logs}) => {
          this.logs = {page, rows: logs};
          this.rs.emitState();
        })
        .catch(e => {
          alert('failed to get device logs');
          console.error(e);
        });
    }
  }
  getDeviceEvents(page) {
    const LIMIT = 25;
    this.events = null;
    if (this.device && this.device !== 'none') {
      const {deviceId} = this.device;
      this.rs.request({
        op: 'getDeviceEvents',
        deviceId,
        offset: page * LIMIT,
        limit: LIMIT
      })
        .then(({events}) => {
          this.events = {page, rows: events};
          this.rs.emitState();
        })
        .catch(e => {
          alert('failed to get device events');
          console.error(e);
        });
    }
  }
  getDeviceUsers() {
    this.users = null;
    if (this.device && this.device !== 'none') {
      const {deviceId} = this.device;
      this.rs.request({
        op: 'getDeviceUsers',
        deviceId
      })
        .then(({users}) => {
          this.users = users;
          this.rs.emitState();
        })
        .catch(e => {
          alert('failed to get users');
          console.error(e);
        });
    }
  }
  toggleDeviceUser(userId, enabled) {
    this.users = this.users.map(u => (
      u.userId !== userId ? u : {
        ...u,
        enabled
      }
    ));
    this.rs.emitState();
    const {deviceId} = this.device;
    return this.rs.request({
      op: 'toggleDeviceUser',
      deviceId,
      userId,
      enabled
    })
      .catch(e => {
        this.users = this.users.map(u => (
          u.userId !== userId ? u : {
            ...u,
            enabled: !enabled
          }
        ));
        this.rs.emitState();
        throw e;
      });
  }
  rebootDevice() {
    if (this.device && this.device !== 'none') {
      const {deviceId} = this.device;
      this.rs.request({
        op: 'rebootDevice',
        deviceId
      })
        .catch(e => {
          alert('failed to reboot device');
          console.error(e);
        });
    }
  }
  updateDeviceList() {
    return this.rs.request({op: 'getDeviceList'})
      .then(({devices}) => {
        this.rs.setDevices(devices);
      });
  }
  deleteDevice(deviceId) {
    return this.rs.request({op: 'deleteDevice', deviceId})
      .then(() => this.updateDeviceList())
      .then(() => this.rs.transitionToState(new StateHome(this.rs)));
  }
  selectDevice(deviceId) {
    this.view = 'status';
    this.device = null;
    return this.rs.request({
      op: 'getDevice',
      deviceId
    })
      .then(({device}) => {
        this.device = device;
        this.rs.emitState();
      })
      .catch(e => {
        alert('failed to load device');
        console.error(e);
      });
  }
  updateDeviceName(deviceId, name) {
    return this.rs.request({
      op: 'updateDeviceName',
      deviceId,
      name
    })
      .then(() => {
        this.updateDeviceList();
        this.selectDevice(deviceId);
        return null;
      })
      .catch(e => {
        alert('failed to update device name');
        console.error(e);
      });
  }
  updateDevicePosition(deviceId, position) {
    return this.rs.request({
      op: 'updateDevicePosition',
      deviceId,
      position
    })
      .then(() => {
        this.updateDeviceList();
        this.selectDevice(deviceId);
        return null;
      });
  }
  updateDeviceState(deviceId, patches) {
    return this.rs.request({
      op: 'updateDeviceState',
      deviceId,
      patches
    })
      .then(() => {
        toast.success('Your update is being processed, it may take few seconds');
        this.updateDeviceList();
        return this.selectDevice(deviceId);
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }
  selectView(v) {
    this.view = v;
    this.rs.emitState();
  }
}
