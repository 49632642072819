import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import './index.scss';


export default class Users extends React.Component {
  constructor(opts) {
    super(opts);
  }

  componentDidMount() {
    this.props.getUsers();
  }

  onToggleUser(userId, currentEnabled, e) {
    const enabled = !currentEnabled;
    this.props.onToggleUser(userId, enabled)
      .then(() => {
        if (enabled) {
          toast.success('Alerts enabled for user on this device');
        } else {
          toast.success('Alerts disabled for user on this device');
        }
      })
      .catch(e => {
        toast.error('Failed to update user settings for this device');
      });
  }

  render() {
    const {users} = this.props;
    if (users === null) {
      return <span>loading device users...</span>;
    }
    return (
      <table className='w-100'>
        <thead>
          <tr>
            <th>User</th>
            <th>Enabled</th>
          </tr>
        </thead>
        <tbody>
          {
            users.map((u, i) => (
              <tr key={i}>
                <td>{u.email}</td>
                <td>
                  <input
                    type='checkbox'
                    checked={u.enabled}
                    onChange={this.onToggleUser.bind(this, u.userId, u.enabled)}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}
