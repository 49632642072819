export default (state) => {
  const {dbState} = state;
  let {reservoirLevel, averageStrokesPerDay} = state;
  let ss = 48;
  let reservoirDaysLeft = null;

  if (dbState) {
    const {reservoirLevel: manualReservoirLevel, strokeCount, strokeSize} = state.dbState;
    ss = strokeSize || 48;
    if (reservoirLevel === null) {
      reservoirLevel = manualReservoirLevel - strokeCount * ss;
      reservoirLevel = Math.max(0, reservoirLevel);
    } else {
      if (dbState.reservoirSize === 'small') {
        reservoirLevel = Math.round((reservoirLevel / 100 * 20) / 2) * 20000;
      } else if (dbState.reservoirSize === 'large') {
        reservoirLevel = Math.round((reservoirLevel / 100 * 50) / 5) * 50000;
      } else if (dbState.reservoirSize === 'xlarge') {
        reservoirLevel = Math.round((reservoirLevel / 100 * 350) / 35) * 350000;
      }
    }
  }

  if (reservoirLevel !== null) {
    if (averageStrokesPerDay !== 0) {
      reservoirDaysLeft = Math.round(reservoirLevel / averageStrokesPerDay / ss);
      if (reservoirDaysLeft > 365) {
        reservoirDaysLeft = `${Math.round(reservoirDaysLeft / 365)} years`;
      } else if (reservoirDaysLeft > 30) {
        reservoirDaysLeft = `${Math.round(reservoirDaysLeft / 30)} months`;
      } else if (reservoirDaysLeft > 7) {
        reservoirDaysLeft = `${Math.round(reservoirDaysLeft / 30)} weeks`;
      } else {
        reservoirDaysLeft = `${reservoirDaysLeft} days`;
      }
    } else {
      reservoirDaysLeft = 'N/A';
    }
    reservoirLevel = (reservoirLevel / 10000).toFixed(2);
    reservoirLevel = `${reservoirLevel}L`;
  } else {
    reservoirLevel = 'N/A';
  }

  return {reservoirLevel, reservoirDaysLeft, strokeSize: ss};
};
