import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import './index.scss';


export default ({state, onSelectDevice}) => {

  const {device, devices} = state;
  const onClickDevice = (deviceId, e) => {
    e.preventDefault();
    onSelectDevice(deviceId);
  }

  let devList = <span className='white'>No devices connected</span>;
  if (devices.length) {
    devList = devices.map(({deviceId, deviceIdShort, name, connected, hasAlarms}, i) => {
      const active = device && device.deviceId === deviceId;
      return (
        <a
          href='#'
          key={i}
          className={classnames(
            'nav-link d-flex justify-content-left px-3 py-2',
            {active: device && device.deviceId === deviceId}
          )}
          onClick={onClickDevice.bind(this, deviceId)}
        >
          <i
            className={classnames(
              'fas fa-circle mr-3',
              {
                'fa-circle': hasAlarms === false,
                'fa-exclamation-triangle': hasAlarms === true,
                'text-success': connected && !active,
                'text-white': connected && active,
                'text-danger': !connected
              }
            )}
          />
          <span>{name ? name : deviceIdShort}</span>
        </a>
      );
    });
  }
  return (
    <div className='nav nav-pills flex-column device-list pt-2 pb-2'>
      {devList}
    </div>
  );
}
