import State from './State';
import StateHome from './StateHome';


export default class StateLoading extends State {
  constructor(rs) {
    super(rs);
  }
  serialize() {
    return {
      name: 'STATE_LOADING'
    };
  }
}
