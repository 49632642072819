import React from 'react';

export default ({ device, version, children }) => {
  console.log(device);
  let validVersion = false;
  if (device && device.state && device.state.dbState) {
    validVersion = device.state.dbState.softwareVersion >= version;
  }

  return (
    <React.Fragment>
      {validVersion && children}
    </React.Fragment>
  );
};
