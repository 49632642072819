import './index.scss';
import React from 'react';

import StyleGuide from '../StyleGuide';
import PasswordForm from '../StyleGuide/PasswordForm';

export default class UserAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userPassword: '',
      userPasswordConfirmation: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  isFormValid() {
    return this.state.userPassword === this.state.userPasswordConfirmation;
  }

  resetState() {
    this.setState({
      loading: false,
      userPassword: '',
      userPasswordConfirmation: ''
     });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    if (!this.isFormValid()) {
      return;
    }

    this.setState({ loading: true });

    rs.performStateOperation('resetUserPassword', this.state.userPassword)
      .then(() => this.resetState())
      .catch(() => this.resetState());
  }

  render() {
    const { rs, state } = this.props;
    const me = rs.getMe();

    return (
      <StyleGuide.Layout rs={rs} state={state}>
        <div className="user-account container">
          <h1>My Account</h1>
          <hr/>
          <div className="row">
            <div className="col-12 col-lg-8">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="userEmail">Email address</label>
                  <input className="form-control" id="userEmail" value={me.email} readOnly autoComplete="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="userRole">Role</label>
                  <input className="form-control" id="userRole" value={me.role} readOnly />
                </div>
                <PasswordForm
                  {...this.state}
                  handleChange={this.handleChange}
                />
                <button type="submit" className="btn btn-primary">
                  {
                    this.state.loading ?
                    'Saving...' :
                    'Save'
                  }
                </button>
              </form>
            </div>
          </div>
        </div>
      </StyleGuide.Layout>
    );
  }
}
