import State from './State';
import { toast } from 'react-toastify';

export default class StateOrganizations extends State {
  constructor(rs) {
    super(rs);
    this.organizations = []
  }
  serialize() {
    return {
      name: 'STATE_ORGANIZATIONS',
      organizations: this.organizations
    };
  }

  onMount() {
    return this.getOrganizations();
  }

  getOrganizations() {
    this.organizations = [];

    return this.rs.request({ op: 'getOrganizations' })
      .then(({ organizations }) => {
        this.organizations = organizations;
        this.rs.emitState();
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  addOrganization(organization) {
    this.rs.request({ op: 'addOrganization', organization })
      .then((response) => {
        toast.success('Organization was added successfully!')
        return this.getOrganizations();
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  updateOrganization(organization) {
    this.rs.request({ op: 'updateOrganization', organization })
      .then(() => {
        toast.success('Organization was updated successfully!')
        return this.getOrganizations();
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  takesOrganizationAdminIdentity(organizationId) {
    this.rs.request({ op: 'takesOrganizationAdminIdentity', organizationId })
      .then(({token}) => {
        localStorage.setItem('auth_token', token);
        location.reload();
      })
      .catch(error => {
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }
}
