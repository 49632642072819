import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class EditOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      organizationName: this.props.organization.name,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    rs.performStateOperation('updateOrganization', {
      id: this.props.organization.id,
      name: this.state.organizationName,
    });

    this.toggle();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-warning btn-sm" onClick={() => this.toggle()}>
          <i className="fa fa-edit"></i>
        </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size="md">
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Edit Organization
            </ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label forhtml="organization-name">Name</label>
                <input
                  type="text" autoFocus className="form-control"
                  id="organization-name" name="organizationName"
                  value={this.state.organizationName} onChange={this.handleChange}
                  required/>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-primary">Save</button>{' '}
              <button type="button" className="btn btn-secondary" onClick={() => this.toggle()}>Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
