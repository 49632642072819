import State from './State';
import { toast } from 'react-toastify';


export default class StateOrganizationUsers extends State {
  constructor(rs) {
    super(rs);
    this.users = []
  }
  serialize() {
    return {
      name: 'STATE_ORGANIZATION_USERS',
      users: this.users
    };
  }

  onMount() {
    return this.getOrganizationUsers();
  }

  getOrganizationUsers() {
    this.users = [];
    return this.rs.request({ op: 'getOrganizationUsers' })
      .then(({ users }) => {
        this.users = users;
        this.rs.emitState();
      })
      .catch(e => {
        toast.error('Failed to get organization users!');
        console.error(e);
      });
  }
}
