import React from 'react';
import classnames from 'classnames';

export default class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
  }

  isPasswordConfirmationWrong() {
    return this.props.userPasswordConfirmation
      && this.props.userPassword !== this.props.userPasswordConfirmation;
  }

  render() {
    return (
      <React.Fragment>
        <div className='form-group'>
          <label forhtml='user-password'>Password</label>
          <input
            type='password'
            className='form-control'
            id='user-password'
            name='userPassword'
            value={this.props.userPassword}
            onChange={this.props.handleChange}
            required
            autoComplete='new-password'/>
        </div>
        <div className='form-group'>
          <label
            forhtml='user-password-confirmation'
          >Repeat Password</label>
          <input
            type='password'
            className={classnames({
              'form-control': true,
              'is-invalid': this.isPasswordConfirmationWrong()
            })}
            id='user-password-confirmation'
            name='userPasswordConfirmation'
            value={this.props.userPasswordConfirmation}
            onChange={this.props.handleChange}
            required
            autoComplete='confirm-password'/>
          <div className='invalid-feedback'>
            Password does not match!
          </div>
        </div>
      </React.Fragment>
    );
  }
}
