import State from './State';
import { toast } from 'react-toastify';


export default class StateReports extends State {
  constructor(rs) {
    super(rs);
    this.report = null;
  }
  serialize() {
    return {
      name: 'STATE_REPORTS',
      devices: this.rs.getDevices(),
      report: this.report
    };
  }
  getReport(reportType, deviceId, startDate, endDate) {
    this.report = [];
    let op = '';
    switch (reportType) {
      case 'alarm':
        op = 'getAlarmReport';
        break;
      case 'audit':
        op = 'getAuditReport';
        break;
      case 'batteryVoltage':
        op = 'getBatteryVoltageReport';
        break;
      case 'grease':
        op = 'getGreaseReport';
        break;
      case 'pressure1':
        op = 'getPressure1Report';
        break;
      case 'pressure2':
        op = 'getPressure2Report';
        break;
      case 'service':
        op = 'getServiceReport';
        break;
      case 'solarVoltage':
        op = 'getSolarVoltageReport';
        break;
      case 'temperature':
        op = 'getTemperatureReport';
        break;
    }
    return this.rs.request({
      op,
      deviceId: deviceId && deviceId !== '' ? deviceId : null,
      startDate,
      endDate
    })
      .then(({report}) => {
        this.report = {
          reportType,
          report
        };
        this.rs.emitState();
      })
      .catch(e => {
        toast.error('Failed to get alarm report!');
        console.error(e);
      });
  }
}
