import './index.scss';
import React from 'react';
import StyleGuide from '../StyleGuide';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class UnassignedDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deviceId: null
    };

    this.toggle = this.toggle.bind(this);
    this.assignDeviceToOrganization = this.assignDeviceToOrganization.bind(this);
  }

  toggle(deviceId) {
    this.setState({
      deviceId: deviceId,
      modal: !this.state.modal
    });
  }

  assignDeviceToOrganization(deviceId, organizationId) {
    const {rs} = this.props;
    rs.performStateOperation('assignDeviceToOrganization', deviceId, organizationId);
    this.toggle();
  }

  render() {
    const { rs, state } = this.props;

    const devices = state.unassignedDevices.map(device => {
      return (
        <div className="col-12 col-md-4" key={device.deviceId}>
          <div className="card device-details">
            <div className="card-body">
              <h5 className="card-title">{device.deviceId}</h5>
              <p className="card-text">{device.name}</p>
              <button onClick={() => this.toggle(device.deviceId)} className="btn btn-primary">Assign To</button>
            </div>
          </div>
        </div>
      );
    });

    const organizations = state.organizations.map(organization => {
      return (
        <a href="#" className="list-group-item list-group-item-action" key={organization.id}
          onClick={() => this.assignDeviceToOrganization(this.state.deviceId, organization.id)}>
          {organization.name}
        </a>
      );
    })

    return (
      <StyleGuide.Layout rs={rs} state={state}>
        <div className='unassigned-devices container'>
          {
            devices.length === 0 &&
            <div className="row">
              <div className="col-12">
                <div className="alert alert-info">
                  No available devices to be assigned
                </div>
              </div>
            </div>
          }
          <div className="row">
            {devices}
          </div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Organizations</ModalHeader>
          <ModalBody>
            <div className="list-group" role="tablist">
              {organizations}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-secondary" onClick={this.toggle}>Cancel</button>
          </ModalFooter>
        </Modal>
      </StyleGuide.Layout>
    );
  }
}
