import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import StateUserAccount from '../RemoteService/StateUserAccount';

export default class NavProfile extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  goToUserAccount() {
    const {rs} = this.props;
    rs.transitionToState(new StateUserAccount(rs));
  }

  logout() {
    localStorage.removeItem('auth_token');
    location.reload();
  }

  render() {
    const me = this.props.rs.getMe();

    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction='left' className='ml-1'>
        <DropdownToggle className='btn-sm rounded' color='primary'>
          <i className='fa fa-user' />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{me.email}</DropdownItem>
          <DropdownItem onClick={this.goToUserAccount.bind(this)}>My Profile</DropdownItem>
          <DropdownItem divider></DropdownItem>
          <DropdownItem onClick={this.logout.bind(this)}>Logout</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
