import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import PasswordForm from '../StyleGuide/PasswordForm';

export default class ResetPasswordOrganizationUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      userPassword: '',
      userPasswordConfirmation: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  stateToUser() {
    return {
      id: this.props.user.id,
      password: this.state.userPassword
    };
  }

  isFormValid() {
    return this.state.userPassword === this.state.userPasswordConfirmation;
  }

  handleSubmit(event) {
    event.preventDefault();
    const {rs} = this.props;

    if (!this.isFormValid()) {
      return;
    }

    this.setState({ loading: true });

    rs.request({ op: 'resetPasswordOrganizationUser', user: this.stateToUser() })
      .then(() => {
        this.setState({ loading: false });
        toast.success('Password was reseted successfully!')
        this.toggle();
      })
      .catch(error => {
        this.setState({ loading: false })
        toast.error(error.msg.description);
        console.log(error.msg);
      });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      userPassword: '',
      userPasswordConfirmation: ''
    });
  }

  render() {
    return (
      <React.Fragment>
      <button type="button" className="btn btn-warning btn-sm" onClick={() => this.toggle()}
        title="Reset Password" >
        <i className="fa fa-lock"></i>
      </button>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size='md'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Reset Password
            </ModalHeader>
            <ModalBody>
              <PasswordForm
                {...this.state}
                handleChange={this.handleChange}
              />
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-primary mr-2'
              >{this.state.loading ? 'Saving...' : 'Save'}</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
