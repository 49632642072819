import React from 'react';


export default ({rows}) => {
  if (rows.length === 0) {
    return <div className='alert alert-warning'>Report is empty.</div>;
  }
  return (
    <table className='table' width='100%'>
      <thead>
        <tr>
          <th>Device</th>
          <th>Service date</th>
        </tr>
      </thead>
      <tbody>
        {
          rows.map((r, i) => (
            <tr key={i}>
              <td>{r.device_name}</td>
              <td>{r.counter_date}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
