import React from 'react';

const ROLE_GUEST = 'GUEST';
const ROLE_TECHNICIAN = 'TECHNICIAN';
const ROLE_ADMIN = 'ADMIN';
const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
const ROLE_HIERARCHY = [ROLE_GUEST, ROLE_TECHNICIAN, ROLE_ADMIN, ROLE_SUPER_ADMIN];

export default ({ user, role, children }) => {
  const userRoleIndex = ROLE_HIERARCHY.indexOf(user.role);
  const requiredRoleIndex = ROLE_HIERARCHY.indexOf(role);
  const hasAuthorization = userRoleIndex >= requiredRoleIndex && requiredRoleIndex >= 0;

  return (
    <React.Fragment>
      {hasAuthorization && children}
    </React.Fragment>
  );
};
