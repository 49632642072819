import React from 'react';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let reservoirSize = null;
    let strokeSize = null;
    let autoReboot = null;
    const {device} = this.props;
    if (device && device.state) {
      const {dbState} = device.state;
      reservoirSize = dbState.reservoirSize;
      autoReboot = dbState.autoReboot;
      strokeSize = dbState.strokeSize;
      if (strokeSize) {
        strokeSize = (strokeSize / 10).toFixed(1);
      }
    }
    this.setState({
      modal: !this.state.modal,
      loading: false,
      reservoirSize,
      autoReboot,
      strokeSize: strokeSize || '4.8',
      strokeSizeError: false,
      techPin: '',
      techPinError: false,
      adminPin: '',
      adminPinError: false
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {device, onSave} = this.props;
    const {adminPin, techPin, reservoirSize, strokeSize, autoReboot} = this.state;

    let errors = null;
    if (adminPin !== '' && !(/^\d*$/g.test(adminPin))) {
      errors = errors || {};
      errors.adminPinError = true;
    }
    if (techPin !== '' && !(/^\d*$/g.test(techPin))) {
      errors = errors || {};
      errors.techPinError = true;
    }
    if (strokeSize === '' || isNaN(strokeSize)) {
      errors = errors || {};
      errors.strokeSizeError = true;
    }

    if (errors !== null) {
      return this.setState(errors);
    }

    this.setState({ loading: true });
    onSave(device.deviceId, {
      adminPin: adminPin === '' ? undefined : adminPin,
      techPin: techPin === '' ? undefined : techPin,
      reservoirSize,
      autoReboot,
      strokeSize: Math.round(Number(strokeSize) * 10),
    })
      .then(() => {
        this.toggle();
      })
      .catch(error => {
        console.log(error.msg);
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  onChange(field, e) {
    let v = e.target.value;
    if (field === 'adminPin' || field === 'techPin') {
      v = v.trim();
    }
    this.setState({
      [field]: v,
      [`${field}Error`]: false
    });
  }

  render() {
    const {
      modal,
      loading,
      adminPin,
      adminPinError,
      techPin,
      techPinError,
      reservoirSize,
      autoReboot,
      strokeSize,
      strokeSizeError,
    } = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          title='Change device settings'
          className='btn btn-outline-dark btn-sm mr-2'
          onClick={() => this.toggle()}
        >
          <i className='fas fa-cog' />
        </button>
        <Modal isOpen={modal} toggle={() => this.toggle()} size='lg' className='device-settings'>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={() => this.toggle()}>
              Device settings
            </ModalHeader>
            <ModalBody>
              <div className='row form-group'>
                <label className='col-4'>Admin pin</label>
                <div className='col-8'>
                  <input
                    type='password'
                    className={classnames('form-control', {'is-invalid': adminPinError})}
                    value={adminPin}
                    onChange={this.onChange.bind(this, 'adminPin')}
                  />
                </div>
              </div>
              <div className='row form-group'>
                <label className='col-4'>Tech pin</label>
                <div className='col-8'>
                  <input
                    type='password'
                    className={classnames('form-control', {'is-invalid': techPinError})}
                    value={techPin}
                    onChange={this.onChange.bind(this, 'techPin')}
                  />
                </div>
              </div>
              <div className='row form-group'>
                <label className='col-4'>Reservoir size</label>
                <div className='col-8 d-flex flex-row'>
                  <div className='form-check mr-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='reservoirSize'
                      id='reservoirSize1'
                      value='small'
                      checked={reservoirSize === 'small'}
                      onChange={this.onChange.bind(this, 'reservoirSize')}
                    />
                    <label className='form-check-label' htmlFor='reservoirSize1'>small</label>
                  </div>
                  <div className='form-check mr-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='reservoirSize'
                      id='reservoirSize2'
                      value='large'
                      checked={reservoirSize === 'large'}
                      onChange={this.onChange.bind(this, 'reservoirSize')}
                    />
                    <label className='form-check-label' htmlFor='reservoirSize2'>large</label>
                  </div>
                  <div className='form-check mr-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='reservoirSize'
                      id='reservoirSize3'
                      value='xlarge'
                      checked={reservoirSize === 'xlarge'}
                      onChange={this.onChange.bind(this, 'reservoirSize')}
                    />
                    <label className='form-check-label' htmlFor='reservoirSize3'>xlarge</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <label className='col-4'>Stroke size</label>
                <div className='col-8'>
                  <input
                    type='text'
                    className={classnames('form-control', {'is-invalid': strokeSizeError})}
                    value={strokeSize}
                    onChange={this.onChange.bind(this, 'strokeSize')}
                  />
                </div>
              </div>
              <div className='row'>
                <label className='col-4'>Auto Reboot</label>
                <div className='mr-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='autoReboot'
                    id='autoReboot1'
                    value='true'
                    checked={autoReboot === 'true'}
                    onChange={this.onChange.bind(this, 'autoReboot')}
                  />
                  <label className='form-check-label' htmlFor='autoReboot1'>Yes</label>
                </div>
                <div className='mr-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='autoReboot'
                    id='autoReboot2'
                    value='false'
                    checked={autoReboot === 'false'}
                    onChange={this.onChange.bind(this, 'autoReboot')}
                  />
                  <label className='form-check-label' htmlFor='autoReboot2'>No</label>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type='submit'
                className='btn btn-primary mr-2'
                onClick={this.handleSubmit}
                disabled={loading}
              >Save</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => this.toggle()}
              >Cancel</button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
