import React from 'react';
import classnames from 'classnames';

import './index.scss';

import Alarms from '../Alarms';
import Events from './Events';
import Logs from './Logs';
import Reboot from './Reboot';
import Status from './Status';
import Terminal from './Terminal';
import Connectivity from './Connectivity';
import Users from './Users';
import LocationForm from './LocationForm';
import SettingsForm from './SettingsForm';
import DeleteForm from './DeleteForm';
import RoleRequired from '../StyleGuide/RoleRequired';
import SoftwareVersionRequired from '../StyleGuide/SoftwareVersionRequired';


export default class Device extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      deviceNameFocused: false,
      deviceName: ''
    };
  }

  componentWillReceiveProps(newProps) {
    const {state} = this.props;
    const {state: newState} = newProps;
    if (state.device.deviceId !== newState.device.deviceId) {
      let deviceName = '';
      const {device} = newState;
      if (device && device !== 'none') {
        deviceName = device.name ? device.name : device.deviceIdShort
      }
      this.setState({
        deviceNameFocused: false,
        deviceName
      });
    }
  }

  onFocusDeviceName(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      deviceNameFocused: true
    });
  }

  onChangeDeviceName(e) {
    this.setState({
      ...this.state,
      deviceName: e.target.value
    });
  }

  onSaveDeviceName(e) {
    e.preventDefault();
    const {state, onSaveDeviceName} = this.props;
    const {device} = state;
    onSaveDeviceName(device.deviceId, this.state.deviceName);
    this.setState({ deviceNameFocused: false });
  }

  onCancelDeviceName(e) {
    e.preventDefault();
    const {state} = this.props;
    this.setState({
      deviceNameFocused: false,
      deviceName: state.device.name ? state.device.name : state.device.deviceIdShort
    });
  }

  onChangeView(view, e) {
    e.preventDefault();
    const {onSelectView} = this.props;
    onSelectView(view);
  }

  render() {
    const {
      deviceNameFocused,
      deviceName
    } = this.state;
    const {
      state,
      rs,
      showAdvanced,
      getAlarms,
      clearAlarm,
      onSelectDevice,
      getEvents,
      getLogs,
      getDeviceUsers,
      toggleDeviceUser,
      onReboot,
      onSaveDevicePosition,
      onSaveDeviceSettings,
      onDeleteDevice
    } = this.props;
    const me = rs.getMe();
    const {
      view,
      device,
      logs,
      events,
      users
    } = state;
    if (device === 'none') {
      const me = rs.getMe();
      return (
        <Alarms
          canClearAlarms={me.role === 'ADMIN' || me.role === 'TECHNICIAN'}
          getAlarms={getAlarms}
          clearAlarm={clearAlarm}
          onSelectDevice={onSelectDevice}
        />
      );
    }
    let content = null;
    switch (view) {
      case 'events':
        content = <Events events={events} getEvents={getEvents} />;
        break;
      case 'logs':
        content = <Logs logs={logs} getLogs={getLogs} />;
        break;
      case 'reboot':
        content = <Reboot onReboot={onReboot} />;
        break;
      case 'status':
        content = <Status device={device} rs={rs} />;
        break;
      case 'terminal':
        content = <Terminal device={device} rs={rs} />;
        break;
      case 'users':
        content =
          <Users
            users={users}
            getUsers={getDeviceUsers}
            onToggleUser={toggleDeviceUser}
          />;
        break;
      case 'connectivity':
        content =
          <Connectivity
            deviceName={deviceName}
          />;
        break;
    }
    return (
      <div className='device'>
        <div className='d-flex justify-content-between mb-3 device-actions'>
          <div className='flex-grow-1 input-group device-name-field mr-2'>
            <input
              className={classnames(
                'form-control device-name-field', {labeled: !deviceNameFocused}
              )}
              type='text'
              placehold='device name'
              value={deviceName}
              onFocus={this.onFocusDeviceName.bind(this)}
              onChange={this.onChangeDeviceName.bind(this)}
            />
            <div className={classnames(
              'input-group-append', {'d-none': !deviceNameFocused}
            )}>
              <button
                className='btn btn-primary'
                type='button'
                onClick={this.onSaveDeviceName.bind(this)}
              ><i className='fas fa-check' /></button>
              <button
                className='btn btn-secondary'
                type='button'
                onClick={this.onCancelDeviceName.bind(this)}
              ><i className='fas fa-times' /></button>
            </div>
          </div>
          <RoleRequired user={rs.getMe()} role="TECHNICIAN">
            <LocationForm device={device} onSave={onSaveDevicePosition} />
          </RoleRequired>
          <RoleRequired user={rs.getMe()} role="SUPER_ADMIN">
            <SoftwareVersionRequired device={device} version='release-181015-1.10.0'>
              <SettingsForm device={device} onSave={onSaveDeviceSettings} />
            </SoftwareVersionRequired>
            <DeleteForm device={device} onDelete={onDeleteDevice} />
          </RoleRequired>
        </div>
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <a
              href='#'
              className={classnames('nav-link', {active: view === 'status'})}
              onClick={this.onChangeView.bind(this, 'status')}
            >Status</a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              className={classnames('nav-link', {active: view === 'events'})}
              onClick={this.onChangeView.bind(this, 'events')}
            >Events</a>
          </li>
          {
            me.role === 'SUPER_ADMIN' ? (
              <li className='nav-item'>
                <a
                  href='#'
                  className={classnames(
                    'nav-link',
                    {active: view === 'logs'}
                  )}
                  onClick={this.onChangeView.bind(this, 'logs')}
                >Logs</a>
              </li>
            ) : null
          }
          {
            me.role === 'SUPER_ADMIN' ? (
              <li className='nav-item'>
                <a
                  href='#'
                  className={classnames(
                    'nav-link',
                    {active: view === 'terminal'}
                  )}
                  onClick={this.onChangeView.bind(this, 'terminal')}
                >Terminal</a>
              </li>
            ) : null
          }
          {
            me.role === 'SUPER_ADMIN' || me.role === 'ADMIN' ? (
              <li className='nav-item'>
                <a
                  href='#'
                  className={classnames(
                    'nav-link',
                    {active: view === 'users'}
                  )}
                  onClick={this.onChangeView.bind(this, 'users')}
                >Users</a>
              </li>
            ) : null
          }
          {
            me.role === 'SUPER_ADMIN' || me.role === 'ADMIN' || true ? (
              <li className='nav-item'>
                <a
                  href='#'
                  className={classnames(
                    'nav-link',
                    {active: view === 'connectivity'}
                  )}
                  onClick={this.onChangeView.bind(this, 'connectivity')}
                >Connectivity</a>
              </li>
            ) : null
          }
          {
            null && (
              <li className='nav-item'>
                <a
                  href='#'
                  className={classnames('nav-link', {active: view === 'reboot'})}
                  onClick={this.onChangeView.bind(this, 'reboot')}
                >Reboot</a>
              </li>
            )
          }
        </ul>
        <div className='tab-content p-4 border border-top-0'>
          <div className='tab-pane active'>{content}</div>
        </div>
      </div>
    );
  }
}
