import React from 'react';


export default ({rows}) => {
  if (rows.length === 0) {
    return <div className='alert alert-warning'>Report is empty.</div>;
  }
  return (
    <table className='table' width='100%'>
      <thead>
        <tr>
          <th>Device</th>
          <th>Date</th>
          <th>Min</th>
          <th>Max</th>
          <th>Avg</th>
        </tr>
      </thead>
      <tbody>
        {
          rows.map((r, i) => (
            <tr key={i}>
              <td>{r.device_name}</td>
              <td>{r.counter_date}</td>
              <td>{r.min === null ? 'N/A' : (r.min / 100).toFixed(2)}</td>
              <td>{r.max === null ? 'N/A' : (r.max / 100).toFixed(2)}</td>
              <td>{r.avg === null ? 'N/A' : (r.avg / 100).toFixed(2)}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
